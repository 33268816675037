<div class="headerLeft">
  <div class="logoCont">
    <a (click)="toggleSidebar()" href="#" aria-label="sidebar" class="sidebar-toggle">
      <nb-icon nbTooltipPlacement="bottom" nbTooltip="Minimize/Maximize Left Panel" icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" (click)="navigateHome()">
      <img src="../../../../assets/images/openbots-cloudserver.jpg" alt="OpenBots Cloud Server Logo" />
    </a>
  </div>
  <!-- <form [formGroup]="headerForm">
    <ng-container *ngIf="myOrganization">
      <label>
        <ng-select [items]="myAppDefinition" [searchable]="true" bindLabel="name" formControlName="appID" id="comment"
          class="NgSelect per-page-drop" [closeOnSelect]="true" bindValue="id" (change)="onAppDefinitionChanged($event)"
          [clearable]="true" placeholder="Please select the Organization Unit">
        </ng-select></label>
    </ng-container>
  </form> -->
  {{billingMessage}}
  <div class="bill" *ngIf="subscriptionStatus == 'trialing' && dueDate > 0 ">
    <p class="billingText">Trial Subscription. Your trial ends in {{dueDate}} days.</p>
    <!-- &nbsp; <a [href]="SubscriptionLink">Click here to update.</a> -->
  </div>
  <div class="bill" *ngIf="subscriptionStatus == 'trialing' && dueDate <=0">
    <p class="billingText">Trial Subscription. Your trial has ended.</p>
    <!-- &nbsp; <a [href]="SubscriptionLink">Click here to update.</a> -->
  </div>
  <div class="bill" *ngIf="subscriptionStatus == 'past_due' ">
    <p class="billingText">Your Subscription is PAST DUE.</p>
    <!-- &nbsp; <a [href]="SubscriptionLink">Click here to update.</a> -->
  </div>
  <div class="bill" *ngIf="subscriptionStatus == 'canceled'">
    <p class="billingText">Your Subscription is CANCELED.</p>
    <!-- &nbsp; <a [href]="SubscriptionLink">Click here to update.</a> -->
  </div>
  <div class="bill" *ngIf="subscriptionStatus == 'unpaid'">
    <p class="billingText">Your Subscription is UNPAID.</p>
    <!-- &nbsp; <a [href]="SubscriptionLink">Click here to update.</a> -->
  </div>
</div>
<div *ngIf="rightMenuOpened" (click)="closeRightMenu()" class="overlay"></div>
<div class="headerRight">
  <div class="navbar navbar-expand-xl navbar-dark pmd-navbar pmd-z-depth">
    <div class="dropdown pmd-dropdown pmd-user-info ml-auto">
      <a (click)="openRightmenu()" class="btn-user dropdown-toggle media align-items-center">
        <img nbTooltip="Account" class="mr-2" src="../../../../assets/images/avatar.jpg" width="40" height="40"
          alt="avatar" />
      </a>

      <div [class.rightMenu]="rightMenuOpened" [class.rightMenuCollapsed]="!rightMenuOpened">
        <div class="avatarPic">
          <img src="../../../../assets/images/avatar.jpg" alt="avatar" />
        </div>
        <div class="avatarPic avatarText">
          {{ userName }} <span (click)="logout()">Logout</span>
        </div>
        <div class="separator separator-dashed"></div>
        <ul class="listStyle">
          <li>
            <form [formGroup]="headerForm">
              <!-- <ng-container *ngIf="myOrganization">
                <select
                  class="custom-select per-page-drop"
                  (change)="onOrganizationChanged($event)"
                  formControlName="id"
                >
                  <option
                    *ngFor="let item of myOrganization"
                    [value]="item?.id"
                  >
                    {{ item?.name }}
                  </option>
                </select> -->
              <label>Organization:</label>
              <ng-container *ngIf="myOrganization">
                <ng-select [items]="myOrganization" [searchable]="true" bindLabel="name" formControlName="id"
                  class="NgSelect  per-page-drop" [closeOnSelect]="true" bindValue="id" placeholder="Please select Org "
                  (change)="onOrganizationChanged($event)" [clearable]="false">
                  <ng-template ng-option-tmp let-item="item">
                    <div [nbTooltip]="item.name">{{item.name}}</div>

                  </ng-template>

                </ng-select>
              </ng-container>
              <div class="scetion">
                <label>Email:</label>
                <p>
                  {{user_email}}
                </p>
                <label>Billing Status:</label>
                <p>{{subscriptionStatus}}</p>
                <label>Next Billing Date:</label>
                <p>{{nextBillDate }}</p>
              </div>

            </form>
          </li>
        </ul>
      </div>
    </div>
  </div>

</div>