import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { FileSizePipe } from 'ngx-filesize';
import { Rule, Usage } from '../../interfaces/ipFencing';
import { ItemsPerPage } from '../../interfaces/itemsPerPage';
import { TimeDatePipe } from '../pipe';
import { AllFileSizePipe } from '../pipe/all-file-size.pipe';
import { TimeZonePipe } from '../pipe/time-zone.pipe';
import { UTCtoLocalTimeZonePipe } from '../pipe/utcto-local-time-zone.pipe';
import { NbToastrService } from '@nebular/theme';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  organizationChanged = new BehaviorSubject<any>(
    localStorage.getItem('organizationId')
  );
  AppDefinitionChanged = new BehaviorSubject<any>(
    localStorage.getItem('AppID')
  );

  itemPerPage: ItemsPerPage[];
  constructor(
    private toastrService: NbToastrService,
    private location: Location
  ) { }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  getItemsPerPage(): ItemsPerPage[] {
    return (this.itemPerPage = [
      // { id: 5, name: '5 per page' },
      { id: 10, name: '10 per page' },
      { id: 20, name: '20 per page' },
      { id: 30, name: '30 per page' },
      { id: 50, name: '50 per page' },
      { id: 100, name: '100 per page' },
    ]);
  }

  transformDate(value, format: string) {
    const pipe = new TimeDatePipe();
    return pipe.transform(value, format);
  }

  changeBoolean(value: boolean | string): string {
    if (value) return 'Yes';
    else return 'No';
  }

  getETagHeaders(etag: string) {
    const headers = new HttpHeaders({ 'If-Match': etag });
    return headers;
  }

  getUsage(): Usage[] {
    return [
      { name: 'Allow', value: 1 },
      { name: 'Deny', value: -1 },
    ];
  }

  getRules(): Rule[] {
    return [
      { name: 'IPv4', value: 1 },
      { name: 'IPv4Range', value: 2 },
      { name: 'IPv6', value: 3 },
      { name: 'IPv6Range', value: 4 },
      { name: 'HTTP Header', value: 5 },
    ];
  }

  getFileSize(param: number): string | string[] {
    const fileSize = new FileSizePipe();
    return fileSize.transform(param);
  }

  getChangedOrgId(): Observable<any> {
    return this.organizationChanged.asObservable();
  }
  getChangedAppId(): Observable<any> {
    return this.AppDefinitionChanged.asObservable();
  }

  UTCTimeToLocal(param: string) {
    const utcTIme = new TimeZonePipe();
    return utcTIme.transform(param);
  }

  localToUTCTime(param: string) {
    return new Date(param);
  }
  bytesIntoMB(bytes: number): number {
    return bytes / (1024 * 1024);
  }

  bytesIntoGB(bytes: number): number {
    return bytes / (1024 * 1024 * 1024);
  }

  megaBytesIntiBytes(bytes: number): number {
    return bytes * (1024 * 1024);
  }

  gegaBytesIntiBytes(bytes: number): number {
    return bytes * (1024 * 1024 * 1024);
  }

  bytesIntoMBorGB(storageSize: number): string[] {
    const data = new AllFileSizePipe();
    return data.transform(storageSize).split(' ');
  }

  UTCtoLocalTimeZone(value, format = 'lll') {
    const convertedTime = new UTCtoLocalTimeZonePipe();
    return convertedTime.transform(value, format);
  }

  success(param): void {
    this.toastrService.success(`${param}`, 'Success');
  }

  error(param): void {
    this.toastrService.danger(`${param}`, 'Error');
  }

  warning(param): void {
    this.toastrService.warning(`${param}`, 'Warning');
  }
  primary(param): void {
    this.toastrService.primary(`${param}`, 'Info');
  }

  info(param): void {
    this.toastrService.info(`${param}`, 'Info');
  }

  changedData(dataArray) {
    return dataArray.map((select) => {
      return Object.assign({}, select, {
        isSelected: false,
      });
    });
  }
  selectAll(dataArray, change) {
    return dataArray.map((select) => {
      return Object.assign({}, select, {
        isSelected: change,
      });
    });
  }

  backScreen(): void {
    this.location.back();
  }
}
