import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'allFileSize',
})
export class AllFileSizePipe implements PipeTransform {
  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  transform(bytes: number = 0, display = null, precision: number = 2): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';
    let unit = 0;
    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }
    if (display) return bytes.toFixed(+precision) + ' ' + this.units[unit];
    else return bytes + ' ' + this.units[unit];
  }
}
