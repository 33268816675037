import { NbIconLibraries, NbMenuItem } from '@nebular/theme';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FeatureFlagService } from '../../../../sharedapp/src/app/sharedServices/feature-flag.service';

@Injectable()
export class PagesMenu {
  iconDate: any;
  constructor(
    private iconLibraries: NbIconLibraries,
    private featureflag: FeatureFlagService
  ) {
    this.iconLibraries.registerSvgPack('custom-icons', {
      agent:
        '<img src="../../assets/icons/Agents.svg" alt="dashboard" style="height: 25px;">',
      agentAll:
        '<img src="../../assets/icons/all-agent.svg" alt="dashboard" style="height: 25px;">',
      allAgentGroup:
        '<img src="../../assets/icons/all-agent-group.svg" alt="dashboard" style="height: 24px;">',
      alerts:
        '<img src="../../assets/icons/alerts.svg" alt="dashboard" style="height: 22px;">',
      automations:
        '<img src="../../assets/icons/Automations.svg" alt="dashboard" style="height: 25px;">',
      credential:
        '<img src="../../assets/icons/credentials.svg" alt="dashboard" style="height: 25px;">',
      credentialPolicy:
        '<img src="../../assets/icons/credentialsPolicy.svg" alt="dashboard">',
      credentialsVault:
        '<img src="../../assets/icons/credentialsVault.svg" alt="dashboard">',
      email: '<img src="../../assets/icons/mail.svg" alt="mail">',
      dashboard:
        '<img src="../../assets/icons/Dashboard.svg" alt="dashboard" style="height: 22px;">',
      file: '<img src="../../assets/icons/File.svg" alt="dashboard">',
      log: '<img src="../../assets/icons/Log.svg" alt="dashboard">',
      process: '<img src="../../assets/icons/Process.svg" alt="dashboard">',
      queue: '<img src="../../assets/icons/Queue.svg" alt="queue">',
      team: '<img src="../../assets/icons/Team.svg" alt="dashboard">',
      config: '<img src="../../assets/icons/config.svg" alt="dashboard">',
      settings: '<img src="../../assets/icons/Settings.svg" alt="dashboard">',
      workItems: '<img src="../../assets/icons/WorkItems.svg" alt="dashboard">',
      addUserGroup:
        '<img src="../../assets/icons/add_usergroup.png" style="height: 25px;" alt="dashboard">',
      areas:
        '<img src="../../assets/icons/areas.svg"  alt="areas" style="height: 25px;">',
      billing:
        '<img src="../../assets/icons/billing.svg" alt="dashboard" style="height: 25px;">',
      center:
        '<img src="../../assets/icons/business-center.svg" alt="dashboard" style="height: 25px;">',
      history:
        '<img src="../../assets/icons/change-history.svg" alt="dashboard" style="height: 25px;">',
      emailLog:
        '<img src="../../assets/icons/all-email-logs.svg" alt="dashboard" style="height: 25px;">',
      emailAccounts:
        '<img src="../../assets/icons/all-email-accounts.svg" alt="dashboard" style="height: 25px;">',
      allSettings:
        '<img src="../../assets/icons/all-settings.svg" alt="dashboard" style="height: 25px;">',
      sendEmail:
        '<img src="../../assets/icons/send-email.svg" alt="dashboard" style="height: 25px;">',
      files:
        '<img src="../../assets/icons/files.svg" alt="dashboard" style="height: 25px;">',
      filesManager:
        '<img src="../../assets/icons/files-manager.svg" alt="dashboard" style="height: 25px;">',
      integration:
        '<img src="../../assets/icons/integration.svg" alt="dashboard" style="height: 25px;">',
      Storage:
        '<img src="../../assets/icons/Storage.svg" alt="dashboard" style="height: 20px;">',
      systemEvents:
        '<img src="../../assets/icons/all-system-events.svg" alt="dashboard" style="height: 25px;">',
      businessEvents:
        '<img src="../../assets/icons/all-business-events.svg" alt="dashboard" style="height: 25px;">',
      mySubscriptions:
        '<img src="../../assets/icons/my-subscriptions.svg" alt="dashboard" style="height: 20px;">',
      allSubscriptions:
        '<img src="../../assets/icons/all-subscriptions.svg" alt="dashboard" style="height: 20px;">',
      myLogs:
        '<img src="../../assets/icons/my-logs.svg"  alt="dashboard" style="height: 20px;">',
      allLogs:
        '<img src="../../assets/icons/all-logs.svg" alt="dashboard" style="height: 20px;">',
      job: '<img src="../../assets/icons/jobs.svg" alt="dashboard" style="height: 21px;">',
      Orchestration:
        '<img src="../../assets/icons/Orchestration.svg" alt="dashboard" style="height: 25px;">',
      storageDrive:
        '<img src="../../assets/icons/storage-drive.svg" alt="dashboard" style="height: 25px;">',
      queues:
        '<img src="../../assets/icons/queue.svg" alt="dashboard" style="height: 25px;">',
      checkpointLogs:
        '<img src="../../assets/icons/all-checkpoint-logs.svg" alt="dashboard" style="height: 20px;">',
      distributedLocks:
        '<img src="../../assets/icons/all-distributed-locks.svg" alt="dashboard" style="height: 25px;">',
      asset:
        '<img src="../../assets/icons/assets.svg" alt="dashboard" style="height: 20px;">',
      security:
        '<img src="../../assets/icons/Security.svg" alt="dashboard" style="height: 23px;">',
      queueItems:
        '<img src="../../assets/icons/all-queue-items.svg" alt="dashboard" style="height: 23px;">',
      schedule:
        '<img src="../../assets/icons/schedules.svg" alt="dashboard" style="height: 25px;">',
      allRoles:
        '<img src="../../assets/icons/all-roles.svg" alt="dashboard" style="height: 25px;">',
      userGroup:
        '<img src="../../assets/icons/all-user-group.svg" alt="dashboard" style="height: 25px;">',
      emails:
        '<img src="../../assets/icons/emails.svg" alt="dashboard" style="height: 23px;">',
      schema:
        '<img src="../../assets/icons/Schema.svg" alt="dashboard" style="height: 23px;">',
    });
  }
  getMenu(): Observable<NbMenuItem[]> {

    const menu: NbMenuItem[] = [
      {
        title: 'Dashboard',
        icon: { icon: 'dashboard', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('Dashboard') == false,
        link: '/pages/dashboard',
        home: true,
        children: undefined,
      },

      {
        title: 'Agents',
        icon: { icon: 'agent', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('Agents') == false,
        children: [
          {
            title: 'Agents',
            icon: { icon: 'agentAll', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllAgents') == false,
            link: '/pages/agents/list',
          },
          {
            title: 'Agent Groups',
            icon: { icon: 'allAgentGroup', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllAgentsGroup') == false,
            link: '/pages/agentgroup/list',
          },
        ],
      },

      {
        title: 'Alerts',
        icon: { icon: 'alerts', pack: 'custom-icons' },
        link: '/pages/alert/list',
      },
      {
        title: 'Assets',
        icon: { icon: 'asset', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('GetAllAssets') == false,
        link: '/pages/asset/list',
      },
      {
        title: 'Automations',
        icon: { icon: 'automations', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('GetAllAutomation') == false,
        link: '/pages/automation/list',
      },
      {
        title: 'Billing',
        icon: { icon: 'billing', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('Billing') == false,
        children: [
          {
            title: 'Orchestration',
            icon: { icon: 'Orchestration', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('Orchestration') == false,
            link: '/pages/billing/list',
          },
          {
            title: 'Storage',
            icon: { icon: 'storageDrive', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('Storage') == false,
            link: '/pages/billing/storage',
          },
        ],
      },
      // {
      //   title: 'Business Center',
      //   icon: { icon: 'center', pack: 'custom-icons' },
      //   url: environment.businessCenter + `?${this.featureflag.getTenantKey(localStorage.getItem('tenantKey'))}`,
      //   target: '_blank',
      // },
      // {
      //   title: 'Calender',
      //   icon: { icon: 'schedule', pack: 'custom-icons' },
      //   link: '/pages/calender',
      //   // hidden: this.featureflag.isFeatureEnabled('Dashboard'),
      // },
      {
        title: 'Change History',
        icon: { icon: 'history', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('GetAllChangeHistory') == false,
        link: '/pages/change-log/list',
      },
      {
        title: 'Credential Vaults',
        icon: { icon: 'credential', pack: 'custom-icons' },
        children: [
          {
            title: 'Vaults',
            link: '/pages/vault',
            icon: { icon: 'credentialsVault', pack: 'custom-icons' },
          },
          {
            title: 'Vaults Policies',
            link: '/pages/credentials/policy',
            icon: { icon: 'credentialPolicy', pack: 'custom-icons' },
          },
        ],
      },
      {
        title: 'Emails',
        icon: { icon: 'emails', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('Emails') == false,
        children: [
          {
            title: 'Email Accounts',
            icon: { icon: 'emailAccounts', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllEmailAccounts') == false,
            link: '/pages/emailaccount/list',
          },
          {
            title: 'Email Logs',
            icon: { icon: 'emailLog', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllEmailLogs') == false,
            link: '/pages/emaillog/list',
          },
          {
            title: 'Send Email',
            icon: { icon: 'sendEmail', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('SendEmail') == false,
            link: '/pages/emailaccount/send-email',
          },
          {
            title: 'Settings',
            icon: { icon: 'allSettings', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('EmailSettings') == false,
            link: '/pages/emailsetting/list',
          },
        ],
      },
      {
        title: 'Files',
        icon: { icon: 'files', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('FilesManager') == false,
        children: [
          {
            title: 'Files Manager',
            icon: { icon: 'filesManager', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('FilesManager') == false,
            link: '/pages/file-manager',
          },
          {
            title: 'Storage Drive',
            icon: { icon: 'Storage', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllStorageDrive') == false,
            link: '/pages/storagedrive',
          },
        ],
      },
      {
        title: 'Integration',
        icon: { icon: 'integration', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('Integration') == false,
        children: [
          {
            title: 'System Events',
            icon: { icon: 'systemEvents', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllSystemEvents') == false,
            link: '/pages/system-event/list',
          },
          {
            title: 'Business Events',
            icon: { icon: 'businessEvents', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllBusinessEvents') == false,
            link: '/pages/business-event/list',
          },
          {
            title: 'My Subscriptions',
            icon: { icon: 'mySubscriptions', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('MySubscription') == false,
            link: '/pages/mysub',
          },
          {
            title: 'Subscriptions',
            icon: { icon: 'allSubscriptions', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllSubscription') == false,
            link: '/pages/subscription/list',
          },
          {
            title: 'My Logs',
            icon: { icon: 'myLogs', pack: 'custom-icons' },
            link: '/pages/mylogs',
          },
          {
            title: 'Logs',
            icon: { icon: 'allLogs', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('MyLog') == false,
            link: '/pages/integration-logs/list',
          },
          {
            title: 'WebHooks',
            icon: { icon: 'schedule', pack: 'custom-icons' },
            // hidden: !this.featureflag.isFeatureEnabled('GetAllSchdules'),
            link: '/pages/webhooks/list',
          },
        ],
      },
      {
        title: 'Jobs',
        icon: { icon: 'job', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('GetAllJobs') == false,
        children: [
          {
            title: 'Jobs',
            icon: { icon: 'job', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllJobs') == false,
            link: '/pages/job/list',
          },
          {
            title: 'Checkpoint Logs',
            icon: { icon: 'checkpointLogs', pack: 'custom-icons' },
            link: '/pages/checkpoint',
          },
          {
            title: 'Distributed Locks',
            icon: { icon: 'distributedLocks', pack: 'custom-icons' },
            link: '/pages/locks/list',
          },
        ],
      },

      // {
      //   title: 'Licenses',
      //   icon: { icon: 'schedule', pack: 'custom-icons' },
      //   // hidden: !this.featureflag.isFeatureEnabled('GetAllSchdules'),
      //   link: '/pages/licenses'
      // },

      {
        title: 'Queues',
        icon: { icon: 'queue', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('Queues') == false,
        children: [
          {
            title: 'Queue Items',
            icon: { icon: 'queueItems', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllQueueItem') == false,
            link: '/pages/queueitems',
          },
          {
            title: 'Queues',
            icon: { icon: 'queue', pack: 'custom-icons' },
            hidden: this.featureflag.isFeatureEnabled('GetAllQueue') == false,
            link: '/pages/queueslist',
          },
        ],
      },
      {
        title: 'Data',
        icon: { icon: 'queue', pack: 'custom-icons' },
        // hidden: this.featureflag.isFeatureEnabled('Queues'),
        children: [
          {
            title: 'My ValueSets',
            icon: { icon: 'queueItems', pack: 'custom-icons' },
            // hidden: this.featureflag.isFeatureEnabled('GetAllQueueItem'),
            link: '/pages/my-valuesets',
          },
          {
            title: 'Global ValueSets',
            icon: { icon: 'queue', pack: 'custom-icons' },
            // hidden: this.featureflag.isFeatureEnabled('GetAllQueue'),
            link: '/pages/global-valuesets',
          },
          {
            title: 'Schema',
            icon: { icon: 'schema', pack: 'custom-icons' },
            link: '/pages/schema/list',
            // hidden: !this.featureflag.isFeatureEnabled('Schemas'),
            children: undefined,
          },
        ],
      },

      {
        title: 'Schedules',
        icon: { icon: 'schedule', pack: 'custom-icons' },
        hidden: this.featureflag.isFeatureEnabled('GetAllSchdules') == false,
        link: '/pages/schedules',
      },

      // {
      //   title: 'Security',
      //   icon: { icon: 'security', pack: 'custom-icons' },
      //   children: [
      //     {
      //       title: 'Areas',
      //       icon: { icon: 'areas', pack: 'custom-icons' },
      //       link: '/pages/app-definition/list',
      //       hidden: !this.featureflag.isFeatureEnabled('Apps'),
      //     },
      //     {
      //       title: 'Roles',
      //       icon: { icon: 'allRoles', pack: 'custom-icons' },
      //       link: '/pages/roles',
      //     },
      //     {
      //       title: 'User Groups',
      //       icon: { icon: 'userGroup', pack: 'custom-icons' },
      //       hidden: !this.featureflag.isFeatureEnabled('UserGroups'),
      //       link: '/pages/usergroup',
      //     }
      //   ],
      // },
    ];
    return of([...menu]);
  }
}
