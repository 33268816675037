
export const EnterpriseEnvironment = {
  production: false,
  //  apiUrl: '/api/v1',
  apiUrl: 'https://openbots-enterpriseserver-api-dev.azurewebsites.net/api/v4',
  healthUrl: '/healthcheck-ui',
  hubUrl: '/notification',
  isDebug: true,
  appConfig: 'appConfig.json',
};
