import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { HttpService } from '../services/http.service';
// import { OAuthService } from 'angular-oauth2-oidc';
import { HelperService } from '../services/helper.service';
// import { authConfig } from '../../oidc-config';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private orgId: string;
  private appDefinitionId: string;
  constructor(
    private httpService: HttpService,
    private authService: AuthService,
    private helperService: HelperService,
    private cookieService: CookieService,
    private router: Router,
  ) { }

  apiUrl() {
    this.helperService.getChangedOrgId().subscribe((response) => {
      if (response == null) {
        this.orgId = localStorage.getItem('organizationId')
      }
      else if (response != null) {
        this.orgId = response
      }
    });
    this.helperService.getChangedAppId().subscribe((response) => {
      this.appDefinitionId = response;
    });
  }
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<any> {
    const token = localStorage.getItem('id_token');
    if (token) {
      this.apiUrl();
      request = this.attachToken(request, token);
      //   this.cookieService.deleteAll();

    }
    return next.handle(request).pipe(
      catchError((error) => {
        this.cookieService.deleteAll();
        this.httpService.stopLoader();

        if (
          (error && error.error && (error.error.status == 401)) ||
          (error && (error.status == 401))
        ) {
          return this.authService.refreshToken().pipe(
            switchMap((data: any) => {
              let authResult = data;
              localStorage.setItem('id_token', authResult.idToken);
              localStorage.setItem('id_token_claims_obj', JSON.stringify(authResult.idTokenClaims));

              return next.handle(this.attachToken(request, authResult.idToken));
            }),
            catchError((error: HttpErrorResponse) => {
              this.authService.login();
              return throwError(error);
            }
            ));

        } else if ((error.error && error.error.status == 403) || (error && (error.status == 403))) {
          this.httpService.error(`${error.error.serviceErrors[0]}`);
        }
        else if (error.error && error.error.status == 409) {
          return throwError(error);
        }
        else if (error.error && error.error.status == 404) {
          return this.handleError404(error)
        }
        else if (error && error.error && error.error.status == 422) {
          this.httpService.error(`${error.error.serviceErrors[0]}`);
        } else if (error && error.error && error.error.status == 402) {
          this.httpService.error('Operation was declined by the server. Payment Required');
        } else if (error && error.error && error.error.status == 429) {
          if (error.headers.get('Retry-After')) {
            if (this.httpService.countapi !== 1) {
              this.httpService.watchtotal(error.error.status, error.headers.get('Retry-After'));
            }
          } else if (this.httpService.countapi !== 1) {
            this.httpService.watchtotal(error.error.status, 30);
          }
        } else if (error && error.error && error.error.status != 401) {
          return this.handleError(error);
        }
      })
    );
  }

  handleError(error) {
    if (error instanceof HttpErrorResponse) {
      if (
        (error.error.status == 400 &&
          error.error.serviceErrors &&
          error.error.serviceErrors[0] ==
          'Token is no longer valid. Please log back in.') ||
        error.error.status == 401 ||
        error.status == 401
      ) {
        this.logoutAndClearSession();
        // this.oAuthService.configure(authConfig);
        // localStorage.clear();
        // sessionStorage.clear();
        // this.cookieService.deleteAll();
        // this.oAuthService.logOut(false);
      } else if (
        error &&
        error.error &&
        error.error.serviceErrors &&
        error.error.serviceErrors[0] !=
        'Token is no longer valid. Please log back in.' &&
        error.error.status == 400 &&
        error.error.serviceErrors
      ) {
        this.httpService.error(`${error.error.serviceErrors[0]}`);
      }
    } else return throwError(error);
  }


  handleError404(error) {
    if (error instanceof HttpErrorResponse) {
      if (
        (error.error.status == 404 &&
          error.error.serviceErrors &&
          error.error.serviceErrors[0] ==
          'Record(s) no longer exists or you do not have authorized access.')
      ) {
        this.router.navigate(['pages/dashboard'])
        this.httpService.error(`${error.error.serviceErrors[0]}`);
      }
    } else return throwError(error);
  }
  attachToken(request: HttpRequest<unknown>, token: string) {

    if (this.appDefinitionId == null) {
      if (request.url.includes('totaldashboardcount') || request.url.includes('ServiceAlerts/Active')) {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            OrganizationId: `${this.orgId}`,
            appID: `11111111-1111-1111-1111-111111111111`,
          },
        });

      } else {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            OrganizationId: `${this.orgId}`,
          },
        });
      }

    } else if (this.appDefinitionId !== null) {
      if (request.url.includes('totaldashboardcount') || request.url.includes('ServiceAlerts/Active') ||
        request.url.includes('automations/GetLookup') || request.url.includes('AgentGroups/getLookUp')
        || request.url.includes('Agents/GetLookup') || request.url.includes('RunNow')
        || request.url.includes('automations/view/') || request.url.includes('Jobs/view/')) {

        if (request.url.includes('totaldashboardcount') || request.url.includes('ServiceAlerts/Active')) {
          return request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              OrganizationId: `${this.orgId}`,
              appID: `11111111-1111-1111-1111-111111111111`,
            },
          });
        } else {
          return request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
              OrganizationId: `${this.orgId}`,
              appID: `${this.appDefinitionId},global`,
            },
          });
        }
      } else {
        return request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
            OrganizationId: `${this.orgId}`,
            appID: `${this.appDefinitionId}`,
          },
        });
      }
    }
  }


  logoutAndClearSession(): void {
    // this.oAuthService.configure(authConfig);
    // localStorage.clear();
    // sessionStorage.clear();
    // this.cookieService.deleteAll();
    // this.oAuthService.logOut(false);
  }

}
