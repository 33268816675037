import { Inject, Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpHeaders } from '@angular/common/http';
// import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { GetUserInfo } from '../../interfaces/getUserInfo';
import { HelperService } from './helper.service';
import { BillingStatusAPiUrl } from '../../webApiUrls/billingStatus';
import { BillingStatus } from '../../interfaces/billingSatus';
import { AppDefinitionApiUrl } from '../../webApiUrls/WorkFlowApiUrls';
import { SharedAppService } from 'projects/sharedapp/src/app/sharedServices/sharedServiceUrl';
import { FeatureFlagService } from '../../../../../sharedapp/src/app/sharedServices/feature-flag.service';
import { SharedHttpService } from 'projects/sharedapp/src/app/sharedServices/shared-http.service';
import { environment } from '../../../../../cloudserver/src/environments/environment';
import { catchError, filter, takeUntil } from 'rxjs/operators';

import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SilentRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
  acr?: string,
  tfp?: string,
};



@Injectable({
  providedIn: 'root',
})
export class AuthService {
  headerData = new HttpHeaders({ 'Content-Type': 'application/json' });
  suscription: Subscription[];
  private loginSubject = new Subject<boolean>();
  loginChanged = this.loginSubject.asObservable();
  rolesPermissionList: any = [];
  userSubjectBehaviour = new BehaviorSubject<any>(null);
  appDefinitionSubjectBehaviour = new BehaviorSubject<any>(null);
  nextBillDateSubjectBehaviour = new BehaviorSubject<any>(null);
  gerUserInfoResponse: GetUserInfo;
  isBlocked: boolean;
  billingStatusSubject = new BehaviorSubject<any>(null);
  chooseCurrentRolesPermission: any = [];

  userAvailable = false;
  constructor(
    private httpService: HttpService,
    // private oAuthService: OAuthService,
    private helperService: HelperService,
    private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private sharedAppService: SharedAppService,
    private sharedHttpService: SharedHttpService,
    private featureFlagService: FeatureFlagService,
    private route: ActivatedRoute
  ) {
    this.loginSubject.next(false);
  }

  refreshToken(): Observable<any> {
    const signInAccount = this.msalService.instance.getActiveAccount();
    var request: SilentRequest = {
      scopes: environment.Azure_AD_B2C.Scope,
      account: signInAccount,
      forceRefresh: true,
    };
    return this.msalService.acquireTokenSilent(request)
  }


  storeToken(data) {
    localStorage.setItem('access_token', data.jwt);
    localStorage.setItem('refresh_token', data.refreshToken);
  }

  isLoggedIn(): boolean {
    // if (this.oAuthService.getAccessToken()) {
    //   const currentUser =
    //     !!this.oAuthService.getAccessToken() &&
    //     !!this.oAuthService.hasValidAccessToken();
    //   this.loginSubject.next(true);
    //   return currentUser;
    // } else {
    return false;
    // }
  }

  // getAppdefinition() {
  // this.httpService
  //   .getByCompleteUrl(
  //     `${AppDefinitionApiUrl.AppDefinitionList}?$orderby=name`
  //   )
  //   .subscribe((response) => {
  //     if (response) {
  //       this.appDefinitionSubjectBehaviour.next(response.items);
  //     }
  //   });
  // }

  featureFlag_and_navigate() {
    this.httpService.get(`FeatureFlags/openbots.cloudserver`).subscribe(
      (response) => {
        if (response) {
          sessionStorage.removeItem('requested_url');
          // this.httpService.getuserInfo().subscribe(res => {
          //   this.userSubjectBehaviour.next(res);
          // });
          this.featureFlagService.config = response;
          if (!sessionStorage.getItem('requested_url')) {
            sessionStorage.setItem(
              'requested_url',
              window.location.href.toString()
            );
          }
          let redirectUrl = sessionStorage.getItem('requested_url');
          let url = redirectUrl.replace(environment.AUTH_REDIRECT_URI, '');

          sessionStorage.removeItem('requested_url');

          if (url == '/' || url == '') {
            this.router.navigate(['/pages/dashboard']);
          } else {
            this.router.navigateByUrl(url);
          }
        }
      },
      () => this.httpService.stopLoader()
    );
  }

  getFeaturesFlag() {
    this.httpService.getUserInformation('Auth/GetUserInfo').subscribe(responseList => {

      if (responseList) {
        // get user info
        let response = responseList;
        // get feature flags

        this.sharedAppService.selectApp(2);
        this.userSubjectBehaviour.next(response);
        this.gerUserInfoResponse = { ...response };
        if (response.myOrganizations && response.myOrganizations.length) {
          this.route.queryParams.subscribe((params) => {

            if (params.trans == 'true' || params.trans != undefined) {
              for (let tKey of response.myOrganizations) {
                if (tKey.tenantKey == params.saveOrgId) {
                  localStorage.setItem('saveOrgId', tKey.tenantKey);
                  localStorage.setItem('organizationId', tKey.id);
                  this.helperService.organizationChanged.next(tKey.id);
                  localStorage.setItem('orgname', tKey.name);
                }
              }
            }
          });
          if (localStorage.getItem('saveOrgId') != null) {
            for (let saveData of response.myOrganizations) {
              if (saveData.id == localStorage.getItem('saveOrgId')) {
                this.sharedAppService.currentCloudOrgAdministrator(
                  saveData.isAdministrator
                );
                this.helperService.organizationChanged.next(saveData.id);
                localStorage.setItem('organizationId', saveData.id);
                localStorage.setItem('tenantKey', saveData.tenantKey);
                localStorage.setItem('orgname', saveData.name);
                this.featureFlagService.getTenantKey(saveData.tenantKey);

                this.sharedAppService.cloudCurrentOrg(saveData.id);
                this.helperService.organizationChanged.next(saveData.id);
              }
            }
          } else if (localStorage.getItem('saveOrgId') == null) {
            this.sharedAppService.currentCloudOrgAdministrator(
              response.myOrganizations[0].isAdministrator
            );
            localStorage.setItem(
              'organizationId',
              response.myOrganizations[0].id
            );
            localStorage.setItem(
              'tenantKey',
              response.myOrganizations[0].tenantKey
            );
            localStorage.setItem('orgname', response.myOrganizations[0].name);
            this.featureFlagService.getTenantKey(
              response.myOrganizations[0].tenantKey
            );

            this.sharedAppService.cloudCurrentOrg(
              response.myOrganizations[0].id
            );
            this.helperService.organizationChanged.next(
              response.myOrganizations[0].id
            );
          }
        }

        localStorage.setItem(
          'forcedPasswordChange',
          response.forcedPasswordChange
        );
        localStorage.setItem(
          'isJoinOrgRequestPending',
          response.isJoinOrgRequestPending
        );
        localStorage.setItem(
          'isUserConsentRequired',
          response.isUserConsentRequired
        );
        localStorage.setItem('personId', response.personId);
        localStorage.setItem('userName', response.userName);
        localStorage.setItem('UserEmail', response.email);
        sessionStorage.setItem('loggedInUser', JSON.stringify(response));

        localStorage.setItem(
          'isOrgAdmin',
          response.myOrganizations[0].isAdministrator
        );
        // this.sharedHttpService.checkRolesStatus(response.myOrganizations[0].id)
        this.getbillingStatus(response.myOrganizations[0].id);

        this.userAvailable = true;
      }
    })
  }

  getUserInfo(): void {
    this.httpService
      .getUserInfoAndFeatures(
        `Auth/GetUserInfo`,
        `FeatureFlags/openbots.cloudserver`
      )
      .subscribe((responseList: any) => {
        if (responseList) {
          // get user info
          let response = responseList[0];
          // get feature flags

          this.sharedAppService.selectApp(2);

          this.featureFlagService.config = responseList[1];
          this.gerUserInfoResponse = { ...response };
          if (response.myOrganizations && response.myOrganizations.length) {
            this.route.queryParams.subscribe((params) => {
              if (params.trans == 'true' || params.trans != undefined) {
                let saveOrgId = '';
                if (params.saveOrgId) {
                  saveOrgId = params.saveOrgId;
                } else if (params.returnurl && params.returnurl.includes('saveOrgId=')) {
                  const orgIdPiece = params.returnurl.split('saveOrgId=');
                  if (orgIdPiece) {
                    const orgId = orgIdPiece[1];
                    saveOrgId = orgId;
                  }
                }

                for (let tKey of response.myOrganizations) {
                  if (tKey.tenantKey == saveOrgId) {
                    localStorage.setItem('saveOrgId', tKey.id);
                    localStorage.setItem('organizationId', tKey.id);
                    this.helperService.organizationChanged.next(tKey.id);
                    localStorage.setItem('orgname', tKey.name);
                  }
                }
              }
            });
            if (localStorage.getItem('saveOrgId') != null) {
              for (let saveData of response.myOrganizations) {
                if (saveData.id == localStorage.getItem('saveOrgId')) {
                  this.sharedAppService.currentCloudOrgAdministrator(
                    saveData.isAdministrator
                  );
                  this.helperService.organizationChanged.next(saveData.id);
                  localStorage.setItem('organizationId', saveData.id);
                  localStorage.setItem('tenantKey', saveData.tenantKey);
                  localStorage.setItem('orgname', saveData.name);
                  this.featureFlagService.getTenantKey(saveData.tenantKey);

                  this.sharedAppService.cloudCurrentOrg(saveData.id);
                  this.helperService.organizationChanged.next(saveData.id);
                }
              }
            } else if (localStorage.getItem('saveOrgId') == null) {
              this.sharedAppService.currentCloudOrgAdministrator(
                response.myOrganizations[0].isAdministrator
              );
              localStorage.setItem(
                'organizationId',
                response.myOrganizations[0].id
              );
              localStorage.setItem(
                'tenantKey',
                response.myOrganizations[0].tenantKey
              );
              localStorage.setItem('orgname', response.myOrganizations[0].name);
              this.featureFlagService.getTenantKey(
                response.myOrganizations[0].tenantKey
              );

              this.sharedAppService.cloudCurrentOrg(
                response.myOrganizations[0].id
              );
              this.helperService.organizationChanged.next(
                response.myOrganizations[0].id
              );
            }
          }

          localStorage.setItem(
            'forcedPasswordChange',
            response.forcedPasswordChange
          );
          localStorage.setItem(
            'isJoinOrgRequestPending',
            response.isJoinOrgRequestPending
          );
          localStorage.setItem(
            'isUserConsentRequired',
            response.isUserConsentRequired
          );
          localStorage.setItem('personId', response.personId);
          localStorage.setItem('userName', response.userName);
          localStorage.setItem('UserEmail', response.email);
          sessionStorage.setItem('loggedInUser', JSON.stringify(response));

          localStorage.setItem(
            'isOrgAdmin',
            response.myOrganizations[0].isAdministrator
          );
          this.userSubjectBehaviour.next(response);

          // this.sharedHttpService.checkRolesStatus(response.myOrganizations[0].id)
          this.getbillingStatus(response.myOrganizations[0].id);

          this.userAvailable = true;
        }
      });
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      } else {
        this.msalService.loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.msalService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
      } else {
        this.msalService.loginRedirect(userFlowRequest);
      }
    }
  }


  getUserInfoBySubject(): Observable<GetUserInfo> {
    return this.userSubjectBehaviour.asObservable();
  }
  getAppDefinitionBySubject(): Observable<any> {
    return this.appDefinitionSubjectBehaviour.asObservable();
  }

  getNextBillDate(): Observable<any> {
    return this.nextBillDateSubjectBehaviour.asObservable();
  }

  getbillingStatus(id?: string): void {
    this.httpService.startLoader();
    // this.getAppdefinition();
    if (id) {
      this.httpService
        .get(
          `${BillingStatusAPiUrl.billing}/${BillingStatusAPiUrl.OrganizationBillingStatus}`
        )
        .subscribe(
          (response: BillingStatus) => {
            if (response) {
              this.nextBillDateSubjectBehaviour.next(response);
              localStorage.setItem(
                'subscriptionStatus',
                response.subscriptionStatus
              );
              localStorage.setItem('nextBillDate', response.nextBillDate);
              localStorage.setItem('isVip', response.isVIP.toString())
              this.isBlocked =
                response.isStorageUploadBlocked ||
                response.isStorageDownloadBlocked ||
                response.isStorageDeletionBlocked ||
                response.areAgentsBlocked ||
                response.isExecutionBlocked ||
                response.isLoggingBlocked;
              this.httpService.stopLoader();
              this.billingStatusSubject.next(this.isBlocked);
              this.organizationBillingStatus(this.isBlocked);
            }
          },
          () => this.httpService.stopLoader()
        );
    } else {
      this.checkBiilingStatus();
    }
  }

  checkBiilingStatus() {
    this.httpService
      .get(
        `${BillingStatusAPiUrl.billing}/${BillingStatusAPiUrl.OrganizationBillingStatus}`
      )
      .subscribe(
        (response: BillingStatus) => {
          if (response) {
            this.nextBillDateSubjectBehaviour.next(response);
            this.isBlocked =
              response.isStorageUploadBlocked ||
              response.isStorageDownloadBlocked ||
              response.isStorageDeletionBlocked ||
              response.areAgentsBlocked ||
              response.isExecutionBlocked ||
              response.isLoggingBlocked;
            this.httpService.stopLoader();
            this.billingStatusSubject.next(this.isBlocked);
            this.organizationBillingStatus(this.isBlocked);
            localStorage.setItem('isVip', response.isVIP.toString())
          }
        },
        () => this.httpService.stopLoader()
      );
  }

  // featureFlag() {

  //   this.httpService
  //     .get(
  //       `FeatureFlags/openbots.cloudserver`
  //     )
  //     .subscribe(
  //       (response) => {
  //         if (response) {

  //           this.featureFlagService.config = response
  //         }
  //       },
  //       () => this.httpService.stopLoader()
  //     );
  // }

  // billing subject
  currentOrgBillingStatus() {
    return this.billingStatusSubject.asObservable();
  }

  organizationBillingStatus(param: boolean) {
    this.sharedAppService.cloudOrgBillingStatus(param);
  }
}
