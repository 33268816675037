import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NgxAuthComponent,
} from './components';

const routes: Routes = [
  {
    path: '',
    component: NgxAuthComponent,
    // children: [
    //   {
    //     path: '',
    //     component: NgxLoginComponent
    //   }
     
    // ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule { }
