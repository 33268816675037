import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
// import { LoginGuard } from './@core/guards/login.guard';
import { TermGuard } from './@core/guards/term.guard';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { LogoutComponent } from './logout/logout.component';

const routes: Routes = [
  {
    path: 'pages',
    canActivate: [MsalGuard],
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
  },
  {
    path: 'workflow-designer',
    loadChildren: () =>
      import('../../../sharedapp/src/app/pages/workflow-designer/workflow-designer.module').then(
        (m) => m.WorkflowDesignerModule
      ),
  },
  {
    path: 'rules-engine',
    loadChildren: () =>
      import('../../../sharedapp/src/app/pages/rules-engine/rules-engine.module').then(
        (m) => m.RulesEngineModule
      ),
  },
  {
    path: 'schema-designer',
    loadChildren: () =>
      import('../../../sharedapp/src/app/pages/schema-designer/schema-designer.module').then(
        (m) => m.SchemaDesignerModule
      ),
  },
  { path: '', redirectTo: 'pages', pathMatch: 'full' },
  // { path: '**', redirectTo: 'pages' },
  { path: 'logout', component: LogoutComponent },
  // { path: 'login', component: LoginComponent },
  {
    // Needed for handling redirect after login
    path: 'signin-oidc',
    component: MsalRedirectComponent
  },
];

const config: ExtraOptions = {
  // Don't perform initial navigation in iframes or popups
  initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
};

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
  })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
