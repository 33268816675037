import { ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpService } from './@core/services/http.service';
// import { OAuthService } from 'angular-oauth2-oidc';
// import { authConfig } from './oidc-config';
import { AuthService } from './@core/services/auth.service';
import { Keepalive } from '@ng-idle/keepalive';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { DialogService } from './@core/dialogservices';

import { takeUntil, filter } from 'rxjs/operators';

import { Subject } from 'rxjs';

import { IdTokenClaims, PromptValue } from '@azure/msal-common';
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest, SsoSilentRequest } from '@azure/msal-browser';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { b2cPolicies } from './auth-config';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

type IdTokenClaimsWithPolicyId = IdTokenClaims & {
    acr?: string,
    tfp?: string,
};

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
    timer: any;
    showScreen: boolean;
    @BlockUI() blockUI: NgBlockUI;
    isConnectionAvailable: boolean = navigator.onLine;
    isIframe = false;
    loginDisplay = false;
    URL: string = '';
    private readonly _destroying$ = new Subject<void>();
    idleState = "NOT_STARTED";
    isConnected = true;
    noInternetConnection: boolean;
    isTokenRefreshed = false;
    countdown?: number | null = null;
    lastPing?: Date | null = null;
    isDisplayed = false;
    setTime = 1200;
    @ViewChild('continueWorkSession') continueWorkSession: TemplateRef<any>;

    constructor(
        private httpService: HttpService,
        // private oAuthService: OAuthService,
        private authService: AuthService,
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private msalService: MsalService,
        private router: Router,
        private msalBroadcastService: MsalBroadcastService,
        private idle: Idle, private keepalive: Keepalive, private cd: ChangeDetectorRef,
        private dialogService: DialogService,
    ) {
        this.setIdleTimeout();
    }

    configureSignOn() {
        // this.oAuthService.configure(authConfig);
        // this.oAuthService.loadDiscoveryDocumentAndLogin().then((success) => {
        // });
    }

    // ngOnInit(): void {
    //   this.reset();
    //   this.toggleBlocking();

    //   var url = window.location.href.toString();
    //   if (!sessionStorage.getItem("requested_url")) {
    //     sessionStorage.setItem("requested_url", window.location.href.toString());
    //   }
    //   // if (url.includes('logout')) {
    //   //   sessionStorage.clear();
    //   //   localStorage.clear();
    //   //   this.oAuthService.configure(authConfig);
    //   //   this.oAuthService.logOut(false);
    //   // } else {
    //   //   if (this.oAuthService.hasValidAccessToken() &&
    //   //     this.oAuthService.hasValidIdToken()) {
    //   //     this.authService.getUserInfo();
    //   //   } else if (!this.oAuthService.hasValidAccessToken() && !this.oAuthService.hasValidIdToken()) {
    //   //     this.oAuthService.configure(authConfig);
    //   //     this.oAuthService.loadDiscoveryDocumentAndLogin().then((response) => {
    //   //       if (response && this.oAuthService.hasValidAccessToken() && !sessionStorage.getItem('loggedInUser'))
    //   //         this.authService.getUserInfo();
    //   //     });
    //   //   }
    //   //   this.oAuthService.events.subscribe((event) => {

    //   //     if (
    //   //       event.type === 'token_error' ||
    //   //       event.type == 'token_validation_error'
    //   //     ) {
    //   //       localStorage.clear();
    //   //       this.oAuthService.logOut();
    //   //     }
    //   //     if (event.type === 'invalid_nonce_in_state') {
    //   //       this.oAuthService.initCodeFlow();
    //   //     }
    //   //     if (event.type === 'token_expires') {
    //   //       this.oAuthService?.refreshToken()
    //   //         .then()
    //   //         .catch((err) => console.log('refresh token error', err));

    //   //     }
    //   //   });
    //   // }
    // }

    // configureSingleSignOn() {
    //   // this.oAuthService.configure(authConfig);
    //   // this.oAuthService.loadDiscoveryDocumentAndLogin().then((response) => {
    //   //   if (response && this.oAuthService.getAccessToken()) {
    //   //     this.authService.getUserInfo();
    //   //   }
    //   // });
    // }

    // toggleBlocking() {
    //   this.httpService.currentMessagetotal.subscribe((res: any) => {
    //     if (res.error == 429) {
    //       let counter = res.time;
    //       const interval = setInterval(() => {
    //         this.blockUI.start(
    //           'Server Busy, You can try after ' + counter + ' Seconds'
    //         );
    //         counter--;
    //         if (counter < 0) {
    //           clearInterval(interval);
    //           this.blockUI.stop();
    //         }
    //         setTimeout(() => {
    //           this.blockUI.stop();
    //         }, 1000);
    //       }, 1000);
    //     }
    //   });
    // }



    ngOnInit(): void {

        if (!sessionStorage.getItem("requested_url")) {
            sessionStorage.setItem("requested_url", window.location.href.toString());
        }

        this.isIframe = window !== window.parent && !window.opener;
        this.URL = window.location.href;
        const accounts = this.msalService.instance.getAllAccounts();
        const queryReturnIsAuth = this.GetParam('isAuthenticated');
        const queryReturnReturn = this.GetParam('returnurl');

        if (queryReturnIsAuth == 'true') {
            if (this.URL.includes('login'))
                window.location.href = window.location.href.replace('/login', '')
        }
        if (accounts.length == 0 && queryReturnIsAuth == 'true') {
            this.authService.login();
        } else if (accounts.length == 0 && queryReturnIsAuth != 'true') {
            if (!this.URL.includes('signin-oidc')) {
                window.location.href = `${environment.Azure_AD_B2C.GalaxyLoginUrl}?returnurl=${window.location.href}`;
            } else {
                this.handleRedirect();
                // this.authService.getUserInfo();
                // this.router.navigate(['pages/dashboard'])
            }
        }

        if (accounts.length > 0) {
            this.handleRedirect();
            // this.router.navigate(['pages/dashboard'])
            // this.authService.getUserInfo();
        }

        /**
     * You can subscribe to MSAL events as shown below. For more info,
     * visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/events.md
     */
        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
            )
            .subscribe((result: EventMessage) => {

                if (this.msalService.instance.getAllAccounts().length === 0) {
                    window.location.pathname = "/";
                } else {
                    this.setLoginDisplay();
                }
            });

        this.msalBroadcastService.inProgress$
            .pipe(
                filter((status: InteractionStatus) => status === InteractionStatus.None),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
                this.checkAndSetActiveAccount();
            })

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS
                    || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
                    || msg.eventType === EventType.SSO_SILENT_SUCCESS),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {

                let payload = result.payload as AuthenticationResult;
                localStorage.setItem('id_token', payload.idToken);
                localStorage.setItem('id_token_claims_obj', JSON.stringify(payload.idTokenClaims));



                let idtoken = payload.idTokenClaims as IdTokenClaimsWithPolicyId;

                if (idtoken.acr === b2cPolicies.names.signUpSignIn || idtoken.tfp === b2cPolicies.names.signUpSignIn) {
                    this.msalService.instance.setActiveAccount(payload.account);
                }

                /**
                 * For the purpose of setting an active account for UI update, we want to consider only the auth response resulting
                 * from SUSI flow. "acr" claim in the id token tells us the policy (NOTE: newer policies may use the "tfp" claim instead).
                 * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                 */
                if (idtoken.acr === b2cPolicies.names.editProfile || idtoken.tfp === b2cPolicies.names.editProfile) {

                    // retrieve the account from initial sing-in to the app
                    const originalSignInAccount = this.msalService.instance.getAllAccounts()
                        .find((account: AccountInfo) =>
                            account.idTokenClaims?.oid === idtoken.oid
                            && account.idTokenClaims?.sub === idtoken.sub
                            && ((account.idTokenClaims as IdTokenClaimsWithPolicyId).acr === b2cPolicies.names.signUpSignIn
                                || (account.idTokenClaims as IdTokenClaimsWithPolicyId).tfp === b2cPolicies.names.signUpSignIn)
                        );

                    let signUpSignInFlowRequest: SsoSilentRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        account: originalSignInAccount
                    };

                    // silently login again with the signUpSignIn policy
                    this.msalService.ssoSilent(signUpSignInFlowRequest);
                }

                /**
                 * Below we are checking if the user is returning from the reset password flow.
                 * If so, we will ask the user to reauthenticate with their new password.
                 * If you do not want this behavior and prefer your users to stay signed in instead,
                 * you can replace the code below with the same pattern used for handling the return from
                 * profile edit flow (see above ln. 74-92).
                 */
                if (idtoken.acr === b2cPolicies.names.resetPassword || idtoken.tfp === b2cPolicies.names.resetPassword) {
                    let signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
                        authority: b2cPolicies.authorities.signUpSignIn.authority,
                        prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
                        scopes: []
                    };

                    this.authService.login(signUpSignInFlowRequest);
                }

                return result;
            });

        this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE || msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
                takeUntil(this._destroying$)
            )
            .subscribe((result: EventMessage) => {
                // Checking for the forgot password error. Learn more about B2C error codes at
                // https://learn.microsoft.com/azure/active-directory-b2c/error-codes
                if (result.error && result.error.message.indexOf('AADB2C90118') > -1) {
                    let resetPasswordFlowRequest: RedirectRequest | PopupRequest = {
                        authority: b2cPolicies.authorities.resetPassword.authority,
                        scopes: [],
                    };

                    this.authService.login(resetPasswordFlowRequest);
                };
            });



    }

    handleRedirect() {

        let redirectUrl = sessionStorage.getItem('requested_url');
        let url = redirectUrl.replace(environment.AUTH_REDIRECT_URI, '');
        sessionStorage.removeItem('requested_url');
        if (url == '/' || url == '') {
            this.router.navigate(['/pages/dashboard']);
        } else {
            this.router.navigateByUrl(url);
        }
    }

    setLoginDisplay() {
        this.loginDisplay = this.msalService.instance.getAllAccounts().length > 0;
    }

    GetParam(name: any) {
        const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (!results) {
            return 0;
        }
        return results[1] || 0;
    }

    checkAndSetActiveAccount() {
        /**
         * If no active account set but there are accounts signed in, sets first account to active account
         * To use active account set here, subscribe to inProgress$ first in your component
         * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
         */
        let activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
            let accounts = this.msalService.instance.getAllAccounts();
            // add your code for handling multiple accounts here
            this.msalService.instance.setActiveAccount(accounts[0]);
        }
    }

    // login(userFlowRequest?: RedirectRequest | PopupRequest) {
    //     if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //         if (this.msalGuardConfig.authRequest) {
    //             this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as PopupRequest)
    //                 .subscribe((response: AuthenticationResult) => {
    //                     this.msalService.instance.setActiveAccount(response.account);
    //                 });
    //         } else {
    //             this.msalService.loginPopup(userFlowRequest)
    //                 .subscribe((response: AuthenticationResult) => {
    //                     this.msalService.instance.setActiveAccount(response.account);
    //                 });
    //         }
    //     } else {
    //         if (this.msalGuardConfig.authRequest) {
    //             this.msalService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    //         } else {
    //             this.msalService.loginRedirect(userFlowRequest);
    //         }
    //     }
    // }


    // logout() {
    //     const activeAccount =
    //       this.authService.instance.getActiveAccount() ||
    //       this.authService.instance.getAllAccounts()[0];

    //     if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
    //       this.authService.logoutPopup({
    //         account: activeAccount,
    //       });
    //     } else {
    //       this.authService.logoutRedirect({
    //         account: activeAccount,
    //       });
    //     }
    // }

    editProfile() {
        let editProfileFlowRequest: RedirectRequest | PopupRequest = {
            authority: b2cPolicies.authorities.editProfile.authority,
            scopes: [],
        };

        this.authService.login(editProfileFlowRequest);
    }

    // unsubscribe to events when component is destroyed
    ngOnDestroy(): void {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }


    reset() {
        // we'll call this method when we want to start/reset the idle process
        // reset any component state and be sure to call idle.watch()
        this.idle.watch();
        this.idleState = "NOT_IDLE";
        this.countdown = null;
        this.lastPing = null;
    }

    onContinueWork(ref) {
        this.isDisplayed = false;
        ref.close();
        this.idle.clearInterrupts();
        this.idle.setIdle(5);
        this.idle.setTimeout(this.setTime);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.reset();
    }
    setIdleTimeout(): void {
        this.idle.setIdle(5);
        this.idle.setTimeout(this.setTime);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleStart.subscribe(() => {
            this.idleState = "IDLE";
        });
        this.idle.onIdleEnd.subscribe(() => {
            this.reset();
            this.idleState = "NOT_IDLE";
            this.countdown = null;
            this.cd.detectChanges();
        });

        this.idle.onTimeout.subscribe(() => {
            this.idleState = "TIMED_OUT";
            sessionStorage.clear();
            localStorage.clear();
            // this.oAuthService.configure(authConfig);
            // this.oAuthService.logOut(false);
        });

        this.idle.onTimeoutWarning.subscribe(seconds => {
            if (seconds <= 300 && !this.isDisplayed) {
                this.idle.clearInterrupts();
                this.isDisplayed = true;
                if (this.isDisplayed)
                    this.dialogService.openDialog(this.continueWorkSession);
            }
            this.countdown = seconds;
        });
        this.keepalive.interval(15);
        this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
    }
}

